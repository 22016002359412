import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import AllBlogPosts from '../component/AllBlogPosts';
import RecentBlogPosts from '../component/RecentBlogPosts';
import blogsData from '../data/blogsData.json';

function BlogDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const blog = blogsData[id];
    return (
        <div className="main-layout">
            <Header />
            <section className="blogs-section blog-details-section">
                <div className="container all-blogs-section">
                    <button onClick={() => navigate('/blog')} className="icon-img">
                        <img src="/assets/images/back-arrow.svg" alt="Back" />
                        Back
                    </button>
                    <img src={blog.src} className="img-fluid mt-5 mb-5" />
                    <h4 className="subheading">{blog.date}</h4>
                    <h1 className="heading">{blog.subheading}</h1>
                    <p className="content">{blog.content2}</p>
                </div>
            </section>
            {/* <RecentBlogPosts /> */}
            <AllBlogPosts />
            <Footer />
        </div>
    )
}

export default BlogDetails;
